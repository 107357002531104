import { DocumentChartBarIcon } from "@heroicons/react/24/solid";
import { Temporal } from "@js-temporal/polyfill";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import API from "~/src/modules/api.js";

import LoadingNotification from "~/src/features/notifications/loading-notification";

import useAnalytics from "~/src/hooks/use-analytics.js";
import useAuth from "~/src/hooks/use-auth";
import useStore from "~/src/hooks/use-store";
import useUserSettings from "~/src/hooks/use-user-settings";

import ExportLimitModal from "../export-limit-modal.jsx";

const {
  Now
} = Temporal;

/**
 *
 * @example
 */
const PdfExportProjectsButton = () => {
  const location = useLocation();

  const { user } = useAuth();

  const exporting = useStore((state) => state.exporting);
  const setExporting = useStore((state) => state.setExporting);

  const { addAnalytics } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  const { userSettings } = useUserSettings();

  const {
    id: userId
  } = user ?? {};

  const reportParameters = new URLSearchParams(location.search);

  reportParameters.set("userId", userId);

  if (!reportParameters.has("page") || location.pathname !== "/liste") {
    reportParameters.set("page", 1);
  }

  if (reportParameters.has("activeBBox") && reportParameters.has("mapZoom")) {
    const zoom = reportParameters.get("mapZoom");
    const activeBBox = reportParameters.get("activeBBox");

    if (activeBBox) {
      reportParameters.set("printZoom", zoom);
    }
  }

  const reportUrl = `${window.settings.BIDRA_API_ENDPOINT}/report?${reportParameters}`;

  const handleClick = async () => {
    const { data: { exportProjects } } = await API.get("/permissions");

    if (exportProjects) {
      setExporting(true);

      const toastId = toast(
        <LoadingNotification>
          PDF wird exportiert...
        </LoadingNotification>,
        {
          autoClose: false,
          bodyClassName: "!p-0 !overflow-visible",
          className: "!p-0 !bg-transparent !shadow-none !overflow-visible",
          closeButton: false,
          closeOnClick: false,
          draggable: false,
          position: "bottom-right",
          transition: Slide
        }
      );

      let response;

      try {
        response = await fetch(reportUrl);

        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = url;

        const date = Now.plainDateISO();

        const standardDateString = date.toString();

        link.setAttribute("download", `Export_${standardDateString}_IMMOdeveloper.pdf`);

        document.body.append(link);

        link.click();

        link.remove();

        window.URL.revokeObjectURL(url);

        toast.dismiss(toastId);

        setExporting(false);
      }
      catch {
        toast.dismiss(toastId);

        setExporting(false);
      }

      addAnalytics({
        action: "export-projects-pdf",
        data: {
          reportUrl,
          userSettings
        }
      });
    }
    else {
      setIsOpen(true);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <button
        className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-primary p-1 hover:bg-primary-dark disabled:cursor-wait disabled:grayscale"
        data-tooltip-content="PDF-Export: bis zu 25 Projekte"
        data-tooltip-id="tooltip-pdf-export"
        disabled={exporting}
        onClick={handleClick}
      >
        <DocumentChartBarIcon className="w-full text-white" />
      </button>

      <Tooltip
        className="z-50"
        delayShow={500}
        id="tooltip-pdf-export"
        place="bottom"
      />

      <ExportLimitModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default PdfExportProjectsButton;
