/* eslint-disable import/max-dependencies */

import { useEffect, useState } from "react";

import DetailStatsTools from "~/src/lib/detail-stats-tools";

import CountWohnungenByYearChart from "~/src/features/charts/count-wohnungen-by-year";
import MarketTrendOfferChart from "~/src/features/charts/market-trend-offer";

import useMediaQuery from "~/src/hooks/use-media-query";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useProjectsDetailStats from "~/src/hooks/use-projects-detail-stats";
import useStore from "~/src/hooks/use-store";

import ChartContainer from "~/src/ui/containers/chart-container";

import { loadingIcon } from "../_common/export.js";
import MarketTrendSaleChart from "../charts/market-trend-sale";
import MarketTrendByAreaOfferChart from "../charts/square-meter-price-by-area-category-offer";
import MarketTrendByAreaSaleChart from "../charts/square-meter-price-by-area-category-sale";
import SquareMeterPriceByAreaOfferChart from "../charts/square-meter-price-by-area-offer";
import SquareMeterPriceByAreaSaleChart from "../charts/square-meter-price-by-area-sale";

/**
 *
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function DetailStatistics() {
  const { query, setQuery } = useProjectQueryParams();
  const {
    detailStats,
    isLoading,
    mutate
  } = useProjectsDetailStats({ query });
  const isPrint = useMediaQuery("print");
  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const [countWohnungenByYear, setCountWohnungenByYear] = useState([]);
  const [marketTrendOffer, setMarketTrendOffer] = useState([]);
  const [marketTrendAreaGroupsOffer, setMarketTrendAreaGroupsOffer] = useState({});
  const [marketTrendSale, setMarketTrendSale] = useState([]);
  const [marketTrendAreaGroupsSale, setMarketTrendAreaGroupsSale] = useState({});
  const [squareMeterPriceByAreaOffer, setSquareMeterPriceByAreaOffer] = useState([]);
  const [squareMeterPriceByAreaSale, setSquareMeterPriceByAreaSale] = useState([]);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutate();
        setSelectionMutate(false);

        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    if (detailStats?.countWohnungenByYear) {
      setCountWohnungenByYear(detailStats.countWohnungenByYear);
    }

    if (detailStats?.marketTrend) {
      setMarketTrendOffer(Object.fromEntries(
        Object.entries(detailStats?.marketTrend)
          .filter(([key]) => !key.includes("Sale"))
      ));

      setMarketTrendSale(Object.fromEntries(
        Object.entries(detailStats?.marketTrend)
          .filter(([key]) => key.includes("Sale"))
      ));
    }

    if (detailStats?.marketTrendAreaGroups) {
      setMarketTrendAreaGroupsOffer(Object.fromEntries(
        Object.entries(detailStats?.marketTrendAreaGroups)
          .filter(([key]) => !key.includes("Sale"))
      ));

      setMarketTrendAreaGroupsSale(Object.fromEntries(
        Object.entries(detailStats?.marketTrendAreaGroups)
          .filter(([key]) => key.includes("Sale"))
      ));
    }

    if (detailStats?.squareMeterPriceByArea) {
      setSquareMeterPriceByAreaOffer(Object.fromEntries(
        Object.entries(detailStats?.squareMeterPriceByArea)
          .filter(([key]) => !key.includes("Sale"))
      ));

      setSquareMeterPriceByAreaSale(Object.fromEntries(
        Object.entries(detailStats?.squareMeterPriceByArea)
          .filter(([key]) => key.includes("Sale"))
      ));
    }
  }, [JSON.stringify(detailStats)]);

  if (isLoading) {
    return (
      <ChartContainer className="flex flex-col gap-4 overflow-y-auto rounded">
        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[450px] w-full bg-white py-3">
          {loadingIcon}
        </div>

        <div className="h-[30px] w-full opacity-0" />
      </ChartContainer>
    );
  }
  const hasData = DetailStatsTools.hasData(detailStats);

  return (
    <>
      {isPrint && hasData &&
        <h2 className="mb-4 text-lg font-medium">Statistiken:</h2>}

      <ChartContainer className="rounded lg:overflow-y-auto print:block print:h-full print:space-y-16 print:divide-neutral-500 print:overflow-y-hidden">
        <CountWohnungenByYearChart
          isError={false}
          statistic={countWohnungenByYear}
        />

        <>
          <MarketTrendOfferChart statistic={marketTrendOffer} />

          <MarketTrendSaleChart statistic={marketTrendSale} />
        </>
        <SquareMeterPriceByAreaOfferChart
          statistic={squareMeterPriceByAreaOffer}
        />

        <SquareMeterPriceByAreaSaleChart
          statistic={squareMeterPriceByAreaSale}
        />

        <>
          <MarketTrendByAreaOfferChart statistic={marketTrendAreaGroupsOffer} />

          <MarketTrendByAreaSaleChart statistic={marketTrendAreaGroupsSale} />
        </>
        <div className="h-[30px] w-full opacity-0" />
      </ChartContainer>
    </>
  );
}
