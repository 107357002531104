/* eslint-disable no-magic-numbers */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty-function */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable import/max-dependencies */

import Leaflet, { LatLngBounds, Polygon } from "leaflet";
import drawLocales from "leaflet-draw-locales";
import { omit } from "radashi";
import {
  useEffect,
  useRef,
  useState
} from "react";
import {
  Circle,
  FeatureGroup,
  MapContainer,
  WMSTileLayer,
  ZoomControl
} from "react-leaflet";

import { parsePolygon } from "~/src/modules/helpers.js";

import ProjectMarkerClusterGroup from "~/src/features/project-marker-cluster-group/index.jsx";

import useMediaQuery from "~/src/hooks/use-media-query.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjectsMap from "~/src/hooks/use-projects-map.js";
import useStore from "~/src/hooks/use-store.js";

import EditControl from "./edit-control.js";
import MapEvents from "./map-events.js";

drawLocales("de-at");

const maxZoom = 18;
const minZoom = 6;

/**
 *
 * @param root0 - The root object
 * @param root0.searchLocation - The root object
 * @param root0.setIsStreetView - The root object
 * @param root0.isSimple - The root object
 * @param root0.projectSlugs - The root object
 * @param root0.automated - The root object
 * @param root0.className - The root object
 * @param root0.forExport - The root object
 * @example
 */
const ProjectsMap = ({
  automated,
  className,
  forExport = false,
  isSimple,
  projectSlugs,
  searchLocation,
  setIsStreetView = () => { }
}) => {
  const [map, setMap] = useState(null);

  const [handleByMove, setHandleByMove] = useState(false);

  const isPrint = useMediaQuery("print");
  const mapReference = useRef(null);
  const groupReference = useRef(null);
  const featureReference = useRef(null);
  const gridReference = useRef(null);
  const searchLocationReference = useRef(null);

  const { query, setQuery } = useProjectQueryParams();

  const [polygonState, setPolygonState] = useState(null);
  const [controlMounted, setControlMounted] = useState(false);
  const [controlState, setControlState] = useState(null);
  const [pageLocked, setPageLocked] = useState(true);

  const mapViewState = useStore((state) => state.mapViewState);
  const setMapViewState = useStore((state) => state.setMapViewState);
  const readPolygonsFromQuery = useStore((state) => state.readPolygonsFromQuery);
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  const clusterState = useStore((state) => state.clusterState);
  const setClusterState = useStore((state) => state.setClusterState);

  const autoZoomed = useStore((state) => state.autoZoomed);
  const setAutoZoomed = useStore((state) => state.setAutoZoomed);

  const [queryChanged, setQueryChanged] = useState(false);

  const previousQuery = useStore((state) => state.prevQuery);
  const setPreviousQuery = useStore((state) => state.setPrevQuery);

  const [currentPoints, setCurrentPoints] = useState([]);
  const [clustersSpiderfied, setClustersSpiderfied] = useState(false);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const statsBoxCollapsed = useStore((state) => state.statsBoxCollapsed);
  const filterTileCollapsed = useStore((state) => state.filterTileCollapsed);
  const miniListTileCollapsed = useStore((state) => state.miniListTileCollapsed);

  const hasBounds = (bounds) => bounds.every((value) => value !== null);

  const {
    bounds,
    clusterExpansionZooms,
    leaves,
    points,
    // eslint-disable-next-line unused-imports/no-unused-vars
    grid,
    isLoading,
    mutate,
    selectionStatus,
    selectionType
  } = useProjectsMap({
    query: {
      ...query,
      // eslint-disable-next-line camelcase
      cluster_state: clusterState,
      forExport,
      isSimple
    }
  });

  useEffect(() => {
    if (!isPrint) {
      setIsStreetView(false);
    }
  }, []);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutate();
        setSelectionMutate(false);
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    if (isSimple && !isPrint) {
      setQuery(
        {
          automated,
          slugs: projectSlugs
        }
      );
    }
  }, []);

  useEffect(() => {
    if (selectionStatus && !isPrint) {
      setCurrentSelectionStatus(selectionStatus);
    }
  }, [selectionStatus]);

  useEffect(() => {
    if (selectionType && !isPrint) {
      setMainSelectionType(selectionType);
    }
  }, [selectionType]);

  useEffect(() => {
    if (handleByMove === false && query.activeBBox && query.mapZoom && query.centerLat && query.centerLng && mapReference.current) {
      const currentMapReference = mapReference.current;

      if (query.centerLat === mapViewState.lat && query.centerLng === mapViewState.lng && query.mapZoom === mapViewState.zoom) {
        const bounds = currentMapReference.getBounds();

        const {
          _northEast,
          _southWest
        } = bounds;

        const boundingBoxString = Leaflet.latLngBounds(
          [[_southWest.lat, _southWest.lng], [_northEast.lat, _northEast.lng]]
        ).toBBoxString();

        if (boundingBoxString !== query.bBox) {
          setQuery({
            bBox: boundingBoxString
          });
        }
      }
      else {
        setMapViewState({
          lat: query.centerLat,
          lng: query.centerLng,
          zoom: query.mapZoom
        });

        currentMapReference.flyTo(
          [query.centerLat, query.centerLng],
          query.mapZoom,
          { duration: 2.5 }
        );
      }
    }
  }, [JSON.stringify(query), mapReference.current]);

  useEffect(() => {
    const filteredQuery = omit(query, [
      "marked",
      "bBox",
      "centerLat",
      "centerLng",
      "mapZoom"
    ]);
    const filteredPreviousQuery = omit(previousQuery, [
      "marked",
      "bBox",
      "centerLat",
      "centerLng",
      "mapZoom"
    ]);

    if (JSON.stringify(filteredQuery) !== JSON.stringify(filteredPreviousQuery)) {
      setPreviousQuery(query);
      if (!queryChanged) {
        setQueryChanged(true);
      }
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (mapReference.current && queryChanged) {
      setAutoZoomed(false);
      setQueryChanged(false);
    }
  }, [currentPoints]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentPoints(points);
      if (selectionLoading) {
        setSelectionLoading(false);
      }
    }
  }, [points]);

  useEffect(() => {
    if (mapReference.current && !isPrint) {
      mapReference.current.invalidateSize();
    }
    if (mapReference.current) {
      mapReference.current.attributionControl.setPrefix("");
    }
  }, [mapReference.current]);

  useEffect(() => {
    if (mapReference.current && !isPrint) {
      mapReference.current.invalidateSize();
    }
  }, [
    statsBoxCollapsed,
    filterTileCollapsed,
    miniListTileCollapsed
  ]);

  useEffect(() => {
    if (!readPolygonsFromQuery) {
      setReadPolygonsFromQuery(false);
      if (polygonState) {
        if (polygonState.length === 0) {
          setPolygonState(null);

          setQuery({
            page: pageLocked && query.page ? query.page : 1,
            polygon: undefined
          });
        }
        else {
          setQuery({
            page: pageLocked && query.page ? query.page : 1,
            polygon: polygonState.map(({ value }) => value)
          });
        }
      }
    }
  }, [polygonState]);

  useEffect(() => {
    if ((!query?.polygon || query.polygon.length === 0) && featureReference.current) {
      setPolygonState([]);

      featureReference.current.eachLayer((layer) => layer.remove());
    }

    if (readPolygonsFromQuery) {
      const queryPolygons = [];

      featureReference?.current?.eachLayer((layer) => layer.remove());

      for (const [index, positions] of query.polygon.entries()) {
        const polygon = new Polygon(
          positions.split("-")
            .map((position) => position.split("~"))
            .map(([lng, lat]) => ({
              lat,
              lng
            })),
          {
            color: "#822c42",
            fillColor: "#822c42",
            weight: 2
          }
        );

        polygon.bindTooltip(`Polygon ${index + 1}`, {
          opacity: 0.8,
          permanent: true
        });

        polygon.openTooltip();

        controlState?.options?.edit?.featureGroup && polygon.addTo(controlState.options.edit.featureGroup);

        queryPolygons.push({
          value: positions,

          id: polygon._leaflet_id
        });
      }

      setPolygonState(queryPolygons);
    }
  }, [query]);

  useEffect(() => {
    setReadPolygonsFromQuery(false);

    if (controlState) {
      const queryPolygons = [];

      for (const [index, positions] of query.polygon.entries()) {
        const polygon = new Polygon(positions.split("-").map((position) => position.split("~")).map(([lng, lat]) => ({
          lat,
          lng
        })), {
          color: "#822c42",
          fillColor: "#822c42",
          weight: 2
        });

        polygon.bindTooltip(`Polygon ${index + 1}`, {
          permanent: true,

          opacity: 0.8
        });

        polygon.openTooltip();

        polygon.addTo(controlState.options.edit.featureGroup);

        queryPolygons.push({
          value: positions,

          id: polygon._leaflet_id
        });
      }

      setPolygonState(queryPolygons);
    }
  }, [controlState]);

  useEffect(() => {
    const currentMapReference = mapReference.current;

    if (!currentMapReference) {
      return;
    }

    if (isLoading) {
      currentMapReference.fireEvent("dataloading");
    }
    else {
      currentMapReference.fireEvent("dataload");
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isPrint) {
      if (searchLocation === null || searchLocationReference?.current) {
        searchLocationReference?.current?.remove();
      }

      if (searchLocation) {
        const currentMapReference = mapReference.current;

        if (currentMapReference) {
          const searchCoords = [searchLocation?.coords?.lat, searchLocation?.coords?.lng];

          currentMapReference.fitBounds([searchCoords]);

          const iconSize = 16;

          const icon = Leaflet.divIcon({
            className: "search-location-marker",
            html: "",
            iconAnchor: [iconSize / 2, iconSize / 2],
            iconSize: [iconSize, iconSize]
          });

          searchLocationReference.current = Leaflet.marker(searchCoords, { icon }).addTo(currentMapReference);
        }
      }
    }
  }, [searchLocation, mapReference.current]);

  const handleCreate = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const polyLayer = event.layer;

    polyLayer.bindTooltip(`Polygon ${(polygonState?.length || 0) + 1}`, {
      opacity: 0.8,
      permanent: true
    });

    polyLayer.openTooltip();

    const polygon = parsePolygon(polyLayer?.getLatLngs());

    setPolygonState([
      ...(polygonState || []),
      {
        id: polyLayer._leaflet_id,
        value: polygon
      }
    ]);
  };

  const handleEdit = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const polygons = [];

    event.layers.eachLayer((layer) => {
      const polyLayer = layer;

      const polygon = parsePolygon(polyLayer?.getLatLngs());

      polygons.push({
        id: polyLayer._leaflet_id,
        value: polygon
      });
    });

    setPolygonState([...polygonState.filter(({ id }) => !polygons.some(({ id: innerId }) => innerId === id)), ...polygons]);
  };

  const handleDelete = function (event) {
    setPageLocked(false);
    setReadPolygonsFromQuery(false);

    const deletedPolygons = [];

    event.layers.eachLayer((layer) => {
      const polyLayer = layer;

      const polygon = parsePolygon(polyLayer?.getLatLngs());

      deletedPolygons.push({
        id: polyLayer._leaflet_id,
        value: polygon
      });

      event.layers.removeLayer(layer);
    });

    for (const [index, layer] of controlState.options.edit.featureGroup.getLayers().entries()) {
      layer.setTooltipContent(`Polygon ${index + 1}`);
    }

    setPolygonState((polygonState || []).filter(({ id }) => !deletedPolygons.find(({ id: innerId }) => innerId === id)));
  };

  const handleMounted = function (control) {
    if (!controlMounted) {
      setControlMounted(true);
      setControlState(control);
    }
  };

  useEffect(() => {
    if (query.activeBBox !== true) {
      if (mapReference.current && !autoZoomed && !isSimple && bounds && hasBounds(bounds)) {
        const currentMapReference = mapReference.current;

        currentMapReference.flyToBounds(new LatLngBounds([bounds[1], bounds[0]], [bounds[3], bounds[2]]), { duration: 1.5 });

        setAutoZoomed(true);
      }
      else if (mapReference.current && isSimple && bounds && hasBounds(bounds)) {
        const currentMapReference = mapReference.current;

        currentMapReference.fitBounds(new LatLngBounds([bounds[1], bounds[0]], [bounds[3], bounds[2]]));

        setAutoZoomed(true);
      }
    }
  }, [
    autoZoomed,
    mapReference.current,
    bounds
  ]);

  const getBBoxFromQuery = (query) => {
    if (query.bBox) {
    // Decode the URL-encoded bboxString
      const bboxString = decodeURIComponent(query.bBox);

      // Convert bboxString into an array of coordinates
      const bboxCoords = bboxString.split(",").map(Number);

      const southwest = [bboxCoords[1], bboxCoords[0]];
      const northeast = [bboxCoords[3], bboxCoords[2]];

      return [southwest, northeast];
    }

    return null;
  };

  const printBBox = getBBoxFromQuery(query);

  useEffect(() => {
    const map = mapReference.current;

    if (map && printBBox && forExport && query.activeBBox) {
      const rectangleLayer = Leaflet.rectangle(printBBox, {
        color: "black",
        dashArray: "12,6",
        fillOpacity: 0,
        weight: 1.1
      });

      rectangleLayer.addTo(map);

      return () => {
        map.removeLayer(rectangleLayer);
      };
    }
  }, [printBBox, mapReference.current]);

  useEffect(() => {
    const mapElement = mapReference.current;

    if (mapElement && printBBox && forExport && query.activeBBox) {
      mapElement.fitBounds(printBBox, {
        maxZoom: 18,
        duration: 0.5,
        padding: [50, 50]
      });
    }
  }, [printBBox, mapReference.current]);

  return (
    <>
      <MapContainer
        center={[mapViewState.lat, mapViewState.lng]}
        className={className}
        dragging={true}
        inertia={false}
        maxZoom={maxZoom}
        minZoom={minZoom}
        preferCanvas={true}
        ref={mapReference}
        scrollWheelZoom={true}
        tap={false}
        whenReady={setMap}
        zoom={mapViewState.zoom}
        zoomControl={false}
        zoomDelta={1}
        zoomSnap={1}
        bounds={
          points
            ?.map(({
              geometry: {
                coordinates: [lng, lat]
              }
            }) => [lat, lng])
        }
        style={{
          backgroundColor: "white"
        }}
      >

        <MapEvents
          {...{
            handleByMove,
            isSimple,
            query,
            setClusterState,
            setHandleByMove,
            setMapViewState,
            setQuery
          }}
        />

        <WMSTileLayer
          attribution={"&copy; <a href=\" http://basemap.at\">Basemap.at</a>"}
          bounds={new LatLngBounds([46.431_817_328_5, 9.479_969_516_65], [49.039_074_205_1, 16.979_666_782_3])}
          format="image/png"
          key="project-map"
          maxNativeZoom={18}
          maxZoom={34}
          minNativeZoom={6}
          noWrap={true}
          updateInterval={50}
          url="https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png"
          version="1.0.0"
          subdomains={[
            "maps",
            "maps1",
            "maps2",
            "maps3",
            "maps4"
          ]}
        />

        {(!isSimple && !isPrint) && <ZoomControl position="topright" />}

        {(
          (!isSimple || isPrint) && (
            <FeatureGroup ref={featureReference}>
              <EditControl
                onCreated={handleCreate}
                onDeleted={handleDelete}
                onEdited={handleEdit}
                onMounted={handleMounted}
                position="topright"
                draw={{
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polygon: {
                    allowIntersection: true,
                    shapeOptions: {
                      color: "#822c42",
                      opacity: 1,
                      weight: 2
                    }
                  },
                  polyline: false,
                  rectangle: false
                }}
              />
            </FeatureGroup>
          )
        )}

        {
          query.street && (
            <Circle
              center={query.street.split(",").map(Number)}
              radius={query.radius * 1_000 || 2_000}
            />
          )
        }

        <FeatureGroup
          ref={groupReference}
        >
          <ProjectMarkerClusterGroup

            {...{
              clusterExpansionZooms,
              clustersSpiderfied,
              groupRef: groupReference,
              isSimple,
              leaves,
              map,
              mapRef: mapReference,
              points: currentPoints,
              setClustersSpiderfied
            }}
          />
        </FeatureGroup>

      </MapContainer>
    </>
  );
};

export default ProjectsMap;
