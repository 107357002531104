import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter";
import { formatNumber } from "~/src/modules/formatters";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectWidmungen() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    widmungen
  } = project;

  const columnTitles = [
    "Plandokument",
    "Kundmachung",
    "Widmung"
  ];

  const rows = widmungen.map(({
    additional,
    bauklasse,
    bauweise,
    height_limit,
    height_limit_sign,
    height_limit_to,
    plan_number,
    publish_date,
    widmung
  }) => [
    plan_number,
    publish_date && dateFormatter(publish_date),
    [
      widmung,
      bauklasse,
      bauweise,
      `${height_limit_sign === true ? "+" : ""}${formatNumber(height_limit)}${height_limit_to ? `-${formatNumber(height_limit_to)}` : ""}${height_limit ? "m" : ""}`,
      additional
    ]
      .filter(Boolean)
      .join(" ")
  ]);

  const columnWidths = [...Array.from({ length: 2 }).fill("2/12"), "8/12"];

  const emptyText = "Flächenwidmungen nicht bekannt";

  return (
    <SectionContainer title="Flächenwidmungen">
      <Table
        {...{
          columnTitles,
          columnWidths,
          emptyText,
          rows
        }}
      />
    </SectionContainer>
  );
}
