import { useParams } from "react-router-dom";

import ProjectCompanyRoles from "~/src/features/project-company-roles";
import ProjectConstructionInfos from "~/src/features/project-construction-infos";
import ProjectConstructionTypes from "~/src/features/project-construction-types/index.new";
import ProjectDescription from "~/src/features/project-description/index.new";
import ProjectEinlagen from "~/src/features/project-einlagen";
import ProjectEnergyClasses from "~/src/features/project-energy-classes";
import ProjectFundings from "~/src/features/project-fundings/index.new";
import ProjectLocations from "~/src/features/project-locations";
import ProjectNotes from "~/src/features/project-notes/index.new";
import ProjectQuantities from "~/src/features/project-quantities/index.new";
import ProjectStatisticsPage from "~/src/features/project-statistics";
import ProjectStatus from "~/src/features/project-status";
import ProjectTimeline from "~/src/features/project-timeline";
import ProjectTransactions from "~/src/features/project-transactions";
import ProjectUnits from "~/src/features/project-units";
import ProjectWidmungen from "~/src/features/project-widmungen";

import useProjectExport from "~/src/hooks/use-project-export";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
export default function ProjectPrintPage() {
  const { id: projectId } = useParams();

  const { project, query } = useProjectExport(projectId);

  if (!project) {
    return null;
  }

  const {
    buyable,
    construction_infos,
    construction_types,
    description,
    einlagen,
    energy_classes,
    funding,
    image_file_name,
    locations,
    name,
    public_notes,
    rentable,
    transactions,
    widmungen
  } = project;

  const location = locations ? locations.find(({ default_location }) => default_location) : {};

  const {
    city: {
      name: cityName,
      zipcode
    } = {},
    housenumber,
    street: {
      name: streetName
    } = {}
  } = location;

  return (
    <div className="space-y-8 space-y-reverse bg-white text-xs" id="print-page">
      <div className="mb-8 flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-1">
          <span className="text-2xl font-bold">{name}</span>

          {locations?.length > 0 && (
            <div>
              <span>{zipcode} {cityName}, </span>

              <span>{streetName}{housenumber && ` ${housenumber}`}</span>
            </div>
          )}
        </div>

        {image_file_name && <img className="h-[6.25rem] w-40 rounded-lg object-cover" src={`https://immodeveloper.fra1.digitaloceanspaces.com/${image_file_name}`} />}
      </div>

      <ProjectStatisticsPage />

      <ProjectStatus />

      <ProjectTimeline />

      <ProjectQuantities />

      <ProjectConstructionInfos />

      <ProjectCompanyRoles />

      {
        description || public_notes
          ? (
            <div className="flex break-inside-avoid gap-8">
              <div className="w-6/12 space-y-3">
                {description && <ProjectDescription />}

                {public_notes && <ProjectNotes />}
              </div>

              <div className="w-6/12">
                <ProjectLocations />
              </div>
            </div>
          )
          : <ProjectLocations />
      }

      <ProjectConstructionTypes />

      <ProjectFundings />

      <ProjectEnergyClasses />

      {(widmungen && widmungen.length > 0) && <ProjectWidmungen />}

      <ProjectEinlagen />

      <ProjectTransactions />

      {query.exportUnits && <ProjectUnits />}

      <div className="relative !mb-6 flex w-full items-center justify-center text-[0.6em]" id="footer">
        <span className="absolute bottom-0 left-0">{Intl.DateTimeFormat("de").format(new Date())}</span>

        <div className="flex flex-col items-center">
          <span>{name}</span>

          <LogoColor className="h-[18px] w-[156px]" />
        </div>

        <div className="absolute bottom-0 right-0"><span className="pageNumber" />/<span className="totalPages" /></div>
      </div>
    </div>
  );
}
