import cn from "classnames";
import { groupBy } from "lodash-es";
import { useParams } from "react-router-dom";

import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

import ProjectUnit from "./project-unit";

/**
 *
 * @example
 */
export default function ProjectUnits() {
  const { id: projectId } = useParams();
  const { units } = useProjectExport(projectId);

  if (!units) {
    return null;
  }

  const groupedUnits = groupBy([...units].sort(({ unit_category: { sorting: sortingA } }, { unit_category: { sorting: sortingB } }) => sortingA - sortingB), "unit_category.code");

  const listFormatter = new Intl.ListFormat("de", {
    style: "long",
    type: "conjunction"
  });

  const columnTitles = ["Objektkategorie", "Erfasste Einheiten"];

  const rows = Object.entries(groupedUnits).map(([categoryCode, units]) => [unitCategoryLabels[categoryCode].singular, units.length]);

  const columnWidths = ["2/12", "10/12"];

  return (
    <SectionContainer wrap className="!mt-0 break-before-page" title="Erfasste Objekte">
      {
        units.length > 0
          ? (
            <>
              <Table
                {...{
                  columnTitles,
                  columnWidths,
                  rows
                }}
              />

              {
                Object.entries(groupedUnits).map(([categoryCode, units], index) => {
                  const categoryLabel = unitCategoryLabels[categoryCode];

                  const title = `${units.length} ${pluralize(categoryLabel.singular, categoryLabel.plural, categoryLabel.plural, units.length)}`;

                  return (
                    <SectionContainer sub wrap className={cn({ "break-before-page": index !== 0 })} key={categoryCode} title={`${title}`}>
                      <>
                        {
                          units.map((unit, index) => (
                            <ProjectUnit
                              {...{
                                index,
                                unit
                              }}
                              key={unit.id}
                            />
                          ))
                        }
                      </>
                    </SectionContainer>
                  );
                })
              }
            </>
          )
          : <p>Keine Objektdaten vorhanden</p>
      }
    </SectionContainer>
  );
}
