import { useParams } from "react-router-dom";

import { constructionTypeLabels } from "~/src/modules/labels";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import AreaRange from "~/src/ui/displays/area-range";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectConstructionTypes() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    construction_types
  } = project;

  const columnTitles = [
    "Bautyp",
    "Wohneinheiten",
    "Wohnfläche",
    "Gesamtfläche (inkl. Gewerbe)"
  ];

  const rows = [
    "neubau",
    "dg_ausbau",
    "sanierung",
    "unsaniert",
    "fremdbestand"
  ]
    .filter((key) => construction_types[key])
    .map((key) => {
      const label = constructionTypeLabels[key];
      const count = construction_types[`${key}_count`];

      const areaRangeFrom = construction_types[`${key}_area_range_from`];
      const areaRangeTo = construction_types[`${key}_area_range_to`];

      const unitAreaTotal = construction_types[`${key}_unit_area_total`];
      const areaTotal = construction_types[`${key}_area_total`];

      return [
        label,
        count,
        (
          unitAreaTotal || areaRangeFrom
            ? (
              <>
                {unitAreaTotal && (<AreaRange area={unitAreaTotal} />)}

                {
                  areaRangeFrom && (
                    unitAreaTotal
                      ? (<> (<AreaRange area={areaRangeFrom} toArea={areaRangeTo} />)</>)
                      : (<AreaRange area={areaRangeFrom} toArea={areaRangeTo} />)
                  )
                }
              </>
            )
            : "-"
        ),
        <AreaRange area={areaTotal} />
      ];
    });

  const columnWidths = [
    "2/12",
    "2/12",
    "4/12",
    "4/12"
  ];

  const emptyText = "Bautypen nicht bekannt";

  return (
    <SectionContainer title="Bautypen">
      <Table
        {...{
          columnTitles,
          columnWidths,
          emptyText,
          rows
        }}
      />
    </SectionContainer>
  );
}
