import cn from "classnames";
import { kebabCase } from "lodash-es";

import Header from "~/src/ui/headers/header";

/**
 *
 * @param root0 - The root object
 * @param root0.title - The root object
 * @param root0.className - The root object
 * @param root0.wrap - The root object
 * @param root0.id - The root object
 * @param root0.sub - The root object
 * @param root0.children - The root object
 * @example
 */
export default function SectionContainer({
  title, id = kebabCase(title), children, className, sub, wrap = false
}) {
  return (
    <div className={cn("w-full space-y-1 space-y-reverse", { "break-inside-avoid": !wrap }, className)} id={id}>
      <Header className="my-2 flex w-full justify-center bg-gray-100 py-1" sub={sub}>{title}</Header>

      {children}
    </div>
  );
}
