import {
  useEffect, useMemo, useState
} from "react";

import dateFormatter from "~/src/modules/date-formatter.js";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import {
  ProjectApartmentsCount,
  ProjectFinishOrHistoricInfo,
  ProjectLocations,
  ProjectPrices,
  ProjectTitle
} from "./project-mini-list-item/_exports.js";

/**
 *
 * @param props - The root object
 * @param props.project - The root object
 * @param props.handleCheckbox - The root object
 * @param props.handleSingleMapProject - The root object
 * @param props.watchlists - The root object
 * @param props.mutateWatchlists - The root object
 * @param props.disabled - The root object
 * @param props.mainSelectionType - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function, complexity
const ProjectMiniListItem = ({
  disabled,
  handleCheckbox,
  project
}) => {
  const mainSelectionType = useStore((state) => state.mainSelectionType);

  const evaluateSelectedState = () => {
    if (mainSelectionType === "addAll") {
      const { unselected } = project;

      return !(unselected);
    }
    else if (mainSelectionType === "removeAll") {
      const { selected } = project;

      return Boolean(selected);
    }
    else if (project.selected) {
      return project.selected;
    }
    else if (project.unselected) {
      return !project.unselected;
    }

    return true;
  };

  const evaluatedSelectedState = useMemo(
    evaluateSelectedState,
    [JSON.stringify(project), mainSelectionType]
  );

  const { query: { sort } } = useProjectQueryParams();
  const [checked, setChecked] = useState(evaluatedSelectedState);

  useEffect(() => {
    setChecked(evaluatedSelectedState);
  }, [JSON.stringify(project), mainSelectionType]);

  const currentSortValue = useMemo(() => {
    if (sort) {
      return sort;
    }

    return null;
  }, [sort]);

  const handleSelect = (event) => {
    setChecked(event.target.checked);
    handleCheckbox(event.target.checked, project.slug);
  };

  const completed = project
    ?.timeline
    ?.current_construction_phase === "construction_phase_completed";

  let completedDate;

  if (completed) {
    completedDate = (
      project?.timeline?.construction_phase_completed_date !== null &&
      project?.timeline?.construction_phase_completed_date_format !== null
    )
      ? dateFormatter(
        project?.timeline?.construction_phase_completed_date,
        project?.timeline?.construction_phase_completed_date_format
      )
      : project.finish_year;
  }

  if (!completed && project?.construction_infos?.estimated_finish_date) {
    completedDate = dateFormatter(
      project.construction_infos.estimated_finish_date,
      project.construction_infos?.estimated_finish_date_format
    );
  }

  const lastContractDate = project?.timeline?.last_contract_date
    ? dateFormatter(project.timeline.last_contract_date, "year")
    : null;

  return (
    <div className="flex w-full flex-col gap-4 border-b border-gray-200 p-3">
      <div className="flex flex-col gap-0.5">
        <ProjectTitle
          {...{
            checked,
            disabled,
            handleSelect,
            project
          }}
        />
      </div>

      <div className="flex flex-col gap-0.5 pl-6">
        <ProjectLocations {...{ project }} />
      </div>

      <div className="flex flex-col gap-0.5 pl-6">
        <ProjectApartmentsCount
          {...{
            currentSortValue,
            project
          }}
        />

        <ProjectFinishOrHistoricInfo
          {...{
            lastContractDate,
            completed,
            completedDate,
            currentSortValue,
            project
          }}
        />
      </div>

      <div className="flex flex-col gap-0.5 pl-6">
        <ProjectPrices
          {...{
            currentSortValue,
            project
          }}
        />
      </div>
    </div>
  );
};

export default ProjectMiniListItem;
