import { groupBy } from "lodash-es";
import { useParams } from "react-router-dom";

import { companyRoleLabels } from "~/src/modules/labels";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectCompanyRoles() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    company_roles
  } = project;

  const columnTitles = [
    "FB-Nr./ZVR-Nr.",
    "Name",
    "Adresse"
  ];

  const tables = Object.entries({
    builder: [],
    marketer: [],
    owner: [],
    ...groupBy(company_roles, "name")
  }).map(([name, companies]) => ({
    emptyText: `${companyRoleLabels[name]} nicht bekannt`,
    rows: companies.map(({
      company: {
        city,
        house_number,
        name: companyName,
        reg_number,
        street,
        zipcode
      }
    }) => [
      reg_number,
      companyName,
      city && `${street} ${house_number}, ${zipcode} ${city}`
    ]),
    title: companyRoleLabels[name]
  }));

  const columnWidths = [
    "2/12",
    "6/12",
    "4/12"
  ];

  return (
    <div>
      {
        tables.map(({
          emptyText, rows, title
        }, index) => (
          <SectionContainer key={title} title={`${title}`}>
            <Table
              {...{
                columnTitles,
                columnWidths,
                emptyText,
                rows
              }}
            />
          </SectionContainer>
        ))
      }
    </div>
  );
}
