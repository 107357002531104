import { useParams } from "react-router-dom";

import { formatNumber } from "~/src/modules/formatters";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectEnergyClasses() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    energy_classes
  } = project;

  const columnTitles = [
    "HWB",
    "fGEE",
    "Bereich/Objekt",
    "GST-Nr."
  ];

  const rows = energy_classes.map(({
    description,
    fgee_class,
    fgee_from,
    fgee_to,
    gst_numbers,
    hwb_class,
    hwb_from,
    hwb_to
  }) => [
    `${formatNumber(hwb_from)}${hwb_to ? ` - ${formatNumber(hwb_to)}` : ""}${hwb_class ? ` (${hwb_class})` : ""}`,
    `${formatNumber(fgee_from)}${fgee_to ? ` - ${formatNumber(fgee_to)}` : ""}${fgee_class ? ` (${fgee_class})` : ""}`,
    gst_numbers.join(", "),
    description
  ]);

  const columnWidths = [...Array.from({ length: 3 }).fill("2/12"), "6/12"];

  const emptyText = "Energieklassen nicht bekannt";

  return (
    <SectionContainer title="Energieklassen">
      <Table
        {...{
          columnTitles,
          columnWidths,
          emptyText,
          rows
        }}
      />
    </SectionContainer>
  );
}
