import { useParams } from "react-router-dom";

import { fundingLabels } from "~/src/modules/labels";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectFundings() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    funding
  } = project;

  const fundingsArray = Object.keys(fundingLabels)
    .filter((key) => funding[key])
    .map((key) => ({
      label: fundingLabels[key],
      value: funding[`${key}_total`]
    }));

  const columnTitles = ["Finanzierung", "Wohneinheiten"];

  const rows = Object.keys(fundingLabels)
    .filter((key) => funding[key])
    .map((key) => {
      const label = fundingLabels[key];
      const value = funding[`${key}_total`];

      return [label, value];
    });

  const columnWidths = ["2/12", "10/12"];

  const emptyText = "Förderungen nicht bekannt";

  return (
    <SectionContainer title="Förderungen">
      <Table
        {...{
          columnTitles,
          columnWidths,
          emptyText,
          rows
        }}
      />
    </SectionContainer>
  );
}
