import { replaceKeyNames, selectByKeys } from "~/src/lib/standard.js";

import { priceLabels } from "./labels/_exports.js";
import defineLabelsGivenKeyAndLabelComponents from "./labels/define-labels-given-key-and-label-components.js";

const errorMessage = {
  statsChart: "Es liegen derzeit nicht genügend Daten für " +
    "eine statistische Auswertung vor."
};
const project = {
  lastContractDate: "Letztes KV-Datum (historische Projekte)",
  addresses: "Adressen",
  automated: "Historisches Projekt",
  completedDate: "Fertigstellung",
  constructionPhase: "Bauphase",
  fundingType: "Förderung",
  marketingPhase: "Vermarktungsphase",
  name: "Projektname",
  projectCertificates: "Gebäudezertifikat",
  toRentOrPurchase: "Rechtsform",
  transactions: "Liegenschaftsankauf",
  unitCount: "Gesamtanzahl Wohneinheiten",
  unitCountAutomated: "Erfasste Transaktionen (historische Projekte)"
};

const transactionCategory = {
  build_law: "Baurecht",
  building: "Gebäude",
  building_part: "Hausanteil",
  interest_house: "Zinshaus",
  interest_house_part: "Zinshausanteil",
  land: "Grundstück"
};

const projectStatusLabel = {
  build_complete: "Projekt fertiggestellt",
  construction: "In Bau",
  land_purchase: "Liegenschaft gekauft",
  marketing: "In Vermarktung",
  units_sold: "vollständig vermarktet"
};

const constructionPhaseLabels = {
  construction_phase_active: "in Bau",
  construction_phase_completed: "Bau abgeschlossen",
  construction_phase_purchase: "Ankauf",
  unknown: "nicht bekannt"
};

const marketingPhaseLabels = {
  "": "-",
  marketing_phase_active: "in Vermarktung",
  marketing_phase_coming_soon: "coming soon",
  marketing_phase_completed_all: "vollständig vermarktet",
  marketing_phase_completed_apartments: "Wohneinheiten vermarktet",
  marketing_phase_initial: "Projekt veröffentlicht",
  marketing_phase_project_finished: "Projekt abgeschlossen",
  unknown: "nicht bekannt"
};

const constructionTypeLabels = {
  dg_ausbau: "DG-Ausbau",
  fremdbestand: "Fremdbestand",
  neubau: "Neubau",
  sanierung: "Sanierung Altbestand",
  unsaniert: "unsaniert"
};

const rechtsformLabels = {
  buyable: "Eigentum",
  buyable_rentable: "Eigentum & Miete",
  rentable: "Miete",
  unknown: "nicht bekannt"
};

const finishYearLabels = {
  2_019: "bis 2019",
  2_020: "2020",
  2_021: "2021",
  2_022: "2022",
  2_023: "2023",
  2_024: "2024",
  2_025: "2025",
  2_026: "ab 2026"
};

const fundingLabels = {
  gemeinde: "Gemeindewohnungen",
  normal_funded: "gefördert",
  not_funded: "freifinanziert",
  smart_funded: "SMART",
  smart_super_funded: "SMART Super",
  super_funded: "Superförderung",
  wiener_wohnbau: "Wiener Wohnbauinitiative"
};

const fundingFilterLabels = {
  freifinanziert: fundingLabels.not_funded,
  gefoerdert: fundingLabels.normal_funded,
  gemeinde: fundingLabels.gemeinde
};

const objektTypFilterLabels = {
  betreuteswohnen: "Betreutes Wohnen",
  doppelhaushaelfte: "Doppelhaushaelfte",
  einfamilienhaus: "Einfamilienhaus",
  reihenhaus: "Reihenhaus",
  servicedapartment: "Serviced Apartment",
  studentenheimplatz: "Studentenheimplatz",
  townhouse: "Town House",
  wohnung: "Wohnung"
};

const quantityLabels = {
  1: "1-9 Wohneinheiten",
  10: "10-24 Wohneinheiten",
  25: "25-49 Wohneinheiten",
  50: "50-99 Wohneinheiten",
  100: "+100 Wohneinheiten"
};

const liegenschaftLabels = {
  baurecht: "Baurecht",
  einfamilienhaus: "Einfamilienhaus",
  gebaeude: "Gebäude",
  grundstueck: "Grundstück",
  hausanteil: "Hausanteil",
  mehrfamilienhaus: "Mehrfamilienhaus",
  mixed: "gemischt",
  rohdachboden: "Rohdachboden",
  sonstiges: "Sonstiges",
  zinshaus: "Zinshaus",
  zinshausanteil: "Zinshausanteil"
};
const sortLabels = {
  city: "Ortschaftsname",
  dateKV: "KV-Datum",
  dateVerbuecherung: "Verbücherungsdatum",
  finishYear: "Fertigstellung (Bau)",
  name: "Projektname",
  plz: "PLZ",
  radius: "Entfernung",
  wohnungen: "Anzahl Wohneinheiten"
};

const sortProjectLabels = new Map([
  ["radius", "Entfernung"],

  ["name", "Projektname"],

  ["finishYear", "Fertigstellung (Bau)"],

  ["countWohnungen", "Anzahl Wohneinheiten"],

  ["zipcode", "PLZ"],

  ["gka", priceLabels.gka],

  ["avgOfferPriceEigennutzer", priceLabels.averageOfferPrice],

  ["avgMiete", priceLabels.averageRent],

  ["avgSalePriceEigennutzer", priceLabels.averageSalePrice]
]);

const shareDealPropertySortLabels = {
  area: "Fläche EZ",
  company: "Eigentümer",
  date: "Datum",
  ez: "EZ",
  fbnr: "Fb-Nr. Eigentümer",
  kg: "KG",
  street: "Straße (Liegenschaft)",
  zipcode: "PLZ (Liegenschaft)"
};

const shareDealTransactionSortLabels = {
  company: "Firmenname",
  date: "Datum",
  fbnr: "Fb-Nr.",
  propertyCount: "Anzahl Liegenschaften"
};

const insolvencySortLabels = {
  company: "Firmenname",
  dateLastChange: "Letzte Änderung",
  datePublished: "Veröffentlichung",
  fbnr: "Fb-Nr.",
  propertyCount: "Anzahl Liegenschaften"
};

const insolvencyPropertySortLabels = {
  area: "Fläche EZ",
  company: "Firmenname",
  dateLastChange: "Letzte Änderung",
  datePublished: "Veröffentlichung",
  ez: "EZ",
  fbnr: "Fb-Nr.",
  kg: "KG",
  street: "Straße (Liegenschaft)",
  zipcode: "PLZ (Liegenschaft)"
};

const unitSortLabels = {
  flaecheAngebot: "Nutzfläche Angebot",
  flaecheVerkauf: "Nutzfläche Verkauf",
  name: "Name",
  priceOfferInvestor: priceLabels.offerPriceInvestorNetPerSquareMeter,
  priceOfferNormal: priceLabels.offerPricePerSquareMeter,
  priceSale: priceLabels.salePricePerSquareMeter,
  rentBk: priceLabels.rentTotalPerSquareMeter,
  rentNetto: priceLabels.rentNetPerSquareMeter
};

const unitCategoryLabels = {
  "bereutes-wohnen": {
    plural: "Betreutes Wohnen",
    singular: "Betreutes Wohnen"
  },
  buero: {
    plural: "Büros",
    singular: "Büro"
  },
  doppelhaushaelfte: {
    plural: "Doppelhaushälften",
    singular: "Doppelhaushälfte"
  },
  einfamilienhaus: {
    plural: "Einfamilienhäuser",
    singular: "Einfamilienhaus"
  },
  garten: {
    plural: "Gärten",
    singular: "Garten"
  },
  geschaeftslokal: {
    plural: "Geschäftslokale",
    singular: "Geschäftslokal"
  },
  hotel: {
    plural: "Hotels",
    singular: "Hotel"
  },
  kindergarten: {
    plural: "Kindergärten",
    singular: "Kindergarten"
  },
  lager: {
    plural: "Lager",
    singular: "Lager"
  },
  "motorrad-parkplatz": {
    plural: "Motorrad-Stellplätze",
    singular: "Motorrad-Stellplatz"
  },
  "pkw-parkplatz": {
    plural: "PKW-Stellplätze",
    singular: "PKW-Stellplatz"
  },
  reihenhaus: {
    plural: "Reihenhäuser",
    singular: "Reihenhaus"
  },
  schule: {
    plural: "Schulen",
    singular: "Schule"
  },
  "serviced-apartment": {
    plural: "Serviced Apartments",
    singular: "Serviced Apartment"
  },
  sonstige: {
    plural: "Sonstige Objekte",
    singular: "Sonstiges Objekt"
  },
  studentenheimplatz: {
    plural: "Studentenheimplätze",
    singular: "Studentenheimplatz"
  },
  townhouse: {
    plural: "Townhouses",
    singular: "Townhouse"
  },
  wohnung: {
    plural: "Wohnungen",
    singular: "Wohnung"
  }
};

const propertyTypeLabels = {
  type_1: "Gebäude mit einer Wohnung",
  type_2: "Gebäude mit zwei oder mehr Wohnungen",
  type_3: "Wohngebäude für Gemeinschaften",
  type_4: "Hotels und ähnliche Gebäude",
  type_5: "Bürogebäude",
  type_6: "Groß- und Einzelhandelsgebäude",
  type_7: "Gebäude des Verkehrs- und Nachrichtenwesens",
  type_8: "Industrie- und Lagergebäude",
  type_9: "Gebäude für Kultur- und Freizeitzwecke sowie das Bildungs- und Gesundheitswesen"
};

const companyRoleLabels = {
  builder: "Bauträger",
  marketer: "Vermarkter",
  owner: "Eigentümer",
  seller: "Verkäufer"
};

const gkaNotices = {
  manual: "(manuelle Berechnung)",
  offer: "(aus Angebotsdaten berechnet)",
  sale: "(aus Verkaufsdaten berechnet)"
};

const countsLabels = {
  projectCount: "Anzahl Projekte",
  unitCount: "Anzahl Wohneinheiten"
};

const priceTypesBuyable = [
  "units-offer-offerPriceNormal",
  "units-offer-offerPriceNormalPerSquareMeter",
  "units-offer-offerPriceInvestorNet",
  "units-offer-offerPriceInvestorNetPerSquareMeter",
  "units-offer-offerPriceInvestorGross",
  "units-offer-offerPriceInvestorGrossPerSquareMeter",

  "units-sale-salePriceNet",
  "units-sale-salePriceNetPerSquareMeter",
  "units-sale-salePriceGross",
  "units-sale-salePriceGrossPerSquareMeter"
];

const labelsBuyable = {
  ...Object.fromEntries(
    priceTypesBuyable
      .map((key) => [key, priceLabels[key.split("-").at(-1)]])
  ),

  "units-dates-saleDate": "Verkaufsdatum",
  "units-dates-verwertetDate": "Verwertungsdatum",

  offer: "Angebot",
  sale: "Verkauf"
};

const dateTypesBuyable = ["units-dates-verwertetDate", "units-dates-saleDate"];

const areaTypesBuyable = ["units-offer-offerArea", "units-sale-saleArea"];

const priceTypesRentable = [
  "units-offer-rentNet",
  "units-offer-rentNetPerSquareMeter",
  "units-offer-rentGross",
  "units-offer-rentGrossPerSquareMeter",
  "units-offer-rentBk",
  "units-offer-rentBkPerSquareMeter"
];

const labelsRentable = {
  ...Object.fromEntries(
    priceTypesRentable
      .map((key) => [key, priceLabels[key.split("-").at(-1).replace("Bk", "Total")]])
  ),

  "units-dates-saleDate": "Verkaufsdatum",
  "units-dates-verwertetDate": "Verwertungsdatum"
};

const dateTypesRentable = ["units-dates-verwertetDate", "units-dates-saleDate"];

const areaTypesRentable = ["units-offer-offerArea"];

const groupModeLabels = {
  grouped: "gruppiert",
  stacked: "gestapelt"
};

const pluralize = ({ language, word }) => (language === "EN"
  ? `${word}s`
  : `${word}e`);

const getExcelProjectsSheetLabels = () => {
  const companyRoleLabelsUsed =
    selectByKeys(companyRoleLabels, [
      "marketer",
      "owner",
      "builder"
    ]);

  return {
    ...selectByKeys(project, [
      "name",
      "addresses",
      "automated",
      "completedDate",
      "lastContractDate",
      "toRentOrPurchase",
      "unitCountAutomated",
      "unitCount",
      "fundingType",
      "marketingPhase",
      "constructionPhase",
      "transactions",
      "projectCertificates"
    ]),
    avgOfferPrice: priceLabels.averageOfferPriceNormal,
    avgOfferPriceInvestor: priceLabels.averageOfferPriceInvestorNet,
    avgRent: priceLabels.averageRentNet,
    avgRentBk: priceLabels.averageRentTotal,
    avgSalePrice: priceLabels.averageSalePriceNormal,
    avgSalePriceInvestor: priceLabels.averageSalePriceInvestorNet,
    gka: priceLabels.gka,
    ...replaceKeyNames(
      companyRoleLabelsUsed,
      (word) => pluralize({
        language: "EN",
        word
      })
    )
  };
};

const getExcelStatsSheetLabels = () => {
  const countsLabelsUsed = selectByKeys(
    countsLabels,
    ["projectCount", "unitCount"]
  );

  return ({
    ...replaceKeyNames(countsLabelsUsed, (keyName) => `${keyName}Selected`),
    gkaAverage: priceLabels.averageGka,
    offerPriceEigennutzerAverage: priceLabels.averageOfferPriceNormal,
    offerPriceInvestorAverage: priceLabels.averageOfferPriceInvestorNet,
    rentAverage: priceLabels.averageRentNet,
    salePriceEigennutzerAverage: priceLabels.averageSalePriceNormal,
    salePriceInvestorAverage: priceLabels.averageSalePriceInvestorNet
  });
};

const unitsStatsKeyAndLabelComponents = {
  fundingTypeKeyAndLabelComponents:
    [["funded", fundingLabels.normal_funded], ["notFunded", fundingLabels.not_funded]],
  quantifierKeyAndLabelComponents: [["", "Einheiten"], ["project", "Projekte"]],
  rechtsformKeyAndLabelComponents:
    [...Object.entries(selectByKeys(rechtsformLabels, ["buyable", "rentable"])), ["unknown", "Unbekannt"]]
};

const getExcelStatsDetailsLabels = () => {
  const concattedKeysAndLabels = defineLabelsGivenKeyAndLabelComponents({
    components: Object.values(selectByKeys(
      unitsStatsKeyAndLabelComponents,
      [
        "quantifierKeyAndLabelComponents",
        "rechtsformKeyAndLabelComponents",
        "fundingTypeKeyAndLabelComponents"
      ]
    )),
    keyComponentPositions: [
      1,
      2,
      0
    ],
    labelComponentPositions:
    [
      2,
      0,
      1
    ],
    labelFormatter: ([
      _fundingType,
      quantifier,
      rechtsform
    ]) => {
      const fundingType = pluralize({
        language: "DE",
        word: _fundingType
      });

      return `${fundingType} ${quantifier}: ${rechtsform}`;
    }

  });

  return {
    finish_year: sortProjectLabels.get("finishYear"),
    projects_sum: `Anzahl: ${unitsStatsKeyAndLabelComponents.quantifierKeyAndLabelComponents[1][1]}`,
    units_sum: `Anzahl: ${unitsStatsKeyAndLabelComponents.quantifierKeyAndLabelComponents[0][1]}`,
    ...concattedKeysAndLabels
  };
};

const excelProjectsLabels = {
  projects: getExcelProjectsSheetLabels(),
  stats: getExcelStatsSheetLabels(),
  statsDetails: getExcelStatsDetailsLabels()
};

const countWohnungenByYearLabels =
    defineLabelsGivenKeyAndLabelComponents(
      {
        components: Object.values(selectByKeys(unitsStatsKeyAndLabelComponents, ["rechtsformKeyAndLabelComponents", "fundingTypeKeyAndLabelComponents"]))
      }
    );

export {
  areaTypesBuyable,
  areaTypesRentable,
  companyRoleLabels,
  constructionPhaseLabels,
  constructionTypeLabels,
  countWohnungenByYearLabels,
  dateTypesBuyable,
  dateTypesRentable,
  errorMessage,
  excelProjectsLabels,
  finishYearLabels,
  fundingFilterLabels,
  fundingLabels,
  gkaNotices,
  groupModeLabels,
  insolvencyPropertySortLabels,
  insolvencySortLabels,
  labelsBuyable,
  labelsRentable,
  liegenschaftLabels,
  marketingPhaseLabels,
  objektTypFilterLabels,

  priceTypesBuyable,
  priceTypesRentable,
  projectStatusLabel,
  propertyTypeLabels,
  quantityLabels,
  rechtsformLabels,
  shareDealPropertySortLabels,
  shareDealTransactionSortLabels,
  sortLabels,
  sortProjectLabels,
  transactionCategory,
  unitCategoryLabels,
  unitSortLabels
};

export { priceLabels } from "./labels/_exports.js";
