import useSWR from "swr";
import {
  BooleanParam, StringParam, encodeQueryParams, useQueryParams
} from "use-query-params";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param projectId
 * @example
 */
export default function useProjectExport(projectId) {
  const parameterConfig = {
    accessToken: StringParam,
    exportUnits: BooleanParam,
    userId: StringParam
  };

  const [query] = useQueryParams(parameterConfig);

  const queryParameters = new URLSearchParams(encodeQueryParams(parameterConfig, query));

  const {
    data, error, mutate
  } = useSWR(`/projects/${projectId}/export?${queryParameters}`, fetcher);

  return {
    error,
    isLoading: !error && !data,
    mutate,
    project: data?.payload.project,
    query,
    units: data?.payload.units,
    userSettings: data?.payload.userSettings
  };
}
