import ProjectFilterTags from "~/src/features/filter-tags/project-filter-tags";
import LegendBox from "~/src/features/legend-box";
import ProjectsMap from "~/src/features/maps/projects-map";
import ProjectCount from "~/src/features/project-count";
import ProjectsListPrintTile from "~/src/features/projects-list-print-tile";
import DetailStatistics from "~/src/features/projects-statistic-tile/chart";
import StatsBox from "~/src/features/stats-box";

import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
const ProjectsPrintPage = () => {
  const { query } = useProjectQueryParams();

  const {
    location: {
      origin,
      search
    }
  } = window;

  const parameters = new URLSearchParams(search);
  const countWohnungenByYearEmpty = useStore((state) => state.countWohnungenByYearEmpty);
  const marketTrendOfferEmpty = useStore((state) => state.marketTrendOfferEmpty);
  const marketTrendSaleEmpty = useStore((state) => state.marketTrendSaleEmpty);

  parameters.delete("page");

  return (
    <>
      <style>
        {`
          *:not(html, #print, #print *) {
            all: unset !important;
          }
          *:not(html, html > body, html > body > div#root, #print, #print *) {
            display: none !important;
          }
        `}
      </style>

      <div className="mx-auto my-0 w-[200mm] space-y-16 space-y-reverse bg-white" id="print">
        <div className="mb-16 mt-2 flex w-full flex-col items-center gap-4">
          <LogoColor />

          <ProjectCount />

          <StatsBox />

          <ProjectFilterTags query={query} />

          <div className="w-full break-inside-avoid">
            <ProjectsMap
              isSimple
              className="mb-4 h-[150mm] rounded border border-gray-200 "
            />

            <LegendBox />
          </div>

        </div>

        <section className="break-before-page">
          <ProjectsListPrintTile />
        </section>

        <section className="break-before-page">
          <h2 className="mb-4 text-lg font-medium">Statistiken:</h2>

          <DetailStatistics />
        </section>
      </div>
    </>
  );
};

export default ProjectsPrintPage;
