import cn from "classnames";
import { useEffect, useState } from "react";

import dateFormatter from "~/src/modules/date-formatter";
import { formatArea, formatEuro } from "~/src/modules/formatters";

import PurchaseSlide from "~/src/features/purchase-slide";

import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.purchases - The root object
 * @param root0.loadingItems - The root object
 * @param root0.query - The root object
 * @param root0.noActiveColumn - The root object
 * @example
 */
export default function PurchaseList({
  isLoading, loadingItems = 5, noActiveColumn, purchases, query
}) {
  const { trackPageView } = useTracking();
  const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
  const [activePurchaseDetail, setActivePurchaseDetail] = useState(false);
  const [activeColumn, setActiveColumn] = useState("dateVerbuecherung");

  /**
   *
   * @param purchase
   * @example
   */
  function handlePurchaseDetailClick(purchase) {
    setActivePurchaseDetail(purchase);
    setShowPurchaseDetails(true);
    trackPageView(purchase.doc_id, "Liegenschaftsankauf");
  }

  /**
   *
   * @example
   */
  function hidePurchaseDetails() {
    setShowPurchaseDetails(false);
  }

  useEffect(() => {
    if (noActiveColumn) {
      setActiveColumn(null);
    }
    else if (query) {
      setActiveColumn(query);
    }
  }, [query]);

  return (
    <div>
      <PurchaseSlide active={showPurchaseDetails} hide={hidePurchaseDetails} purchase={activePurchaseDetail} />

      <div className="mb-4 ml-4 mt-2 flex flex-col sm:ml-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" id="scrollable-table">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 shadow-sm sm:rounded">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700" scope="col">
                      Verbücherung
                    </th>

                    <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase text-gray-700" scope="col">
                      KV-Datum
                    </th>

                    <th className="px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700" scope="col">
                      Adresse
                    </th>

                    <th className="px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700" scope="col">
                      Käufer
                    </th>

                    <th className="px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700" scope="col">
                      Objektart
                    </th>

                    <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase text-gray-700" scope="col">
                      GST-Fläche
                    </th>

                    <th className="px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700" scope="col">
                      Kaufpreis
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading &&
                    [...new Array(loadingItems)].map((e, index) => (
                      <tr className="cursor-pointer hover:bg-gray-50" key={`purchase_skeleton_${index}`}>
                        <td className="h-16 w-20 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 w-20 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 w-64 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 max-w-xs px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 w-32 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 w-20 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>

                        <td className="h-16 w-20 px-4"><span className="block h-5 w-full rounded bg-gray-300" /></td>
                      </tr>
                    ))}

                  {purchases?.length === 0 && (
                    <tr className="bg-white">
                      <td className="whitespace-nowrap p-4 text-center font-light text-gray-900" colSpan="7">keine Transaktionen für die ausgewählten Kriterien gefunden</td>
                    </tr>
                  )}

                  {purchases?.map((purchase) => {
                    const purchaseBuyer = purchase?.buyers[0];

                    return (
                      <tr className="cursor-pointer hover:bg-gray-50" key={purchase.id} onClick={() => handlePurchaseDetailClick(purchase)}>
                        <td
                          className={cn("px-4 py-4 w-20 whitespace-nowrap text-sm text-center", {
                            "text-gray-900 font-medium": activeColumn !== "dateVerbuecherung",
                            "text-primary font-bold": activeColumn === "dateVerbuecherung"
                          })}
                        >
                          {dateFormatter(purchase.date_verbuecherung)}
                        </td>

                        <td
                          className={cn("px-4 py-4 w-20 whitespace-nowrap text-sm text-center", {
                            "text-gray-900 font-medium": activeColumn !== "dateKV",
                            "text-primary font-bold": activeColumn === "dateKV"
                          })}
                        >
                          {dateFormatter(purchase.date_kv)}
                        </td>

                        <td className="w-64 truncate whitespace-nowrap p-4 text-sm text-gray-600">
                          <span
                            className={cn({
                              "text-primary font-bold": activeColumn === "plz"
                            })}
                          >
                            {purchase.zipcode}

                            {" "}

                            {purchase.city}
                          </span>

                          {purchase.street ? "," : ""}

                          {" "}

                          {purchase.street}

                          {" "}

                          {purchase.housenumber}

                          <br />

                          {purchase.state}
                        </td>

                        <td className="max-w-xs truncate whitespace-nowrap p-4 text-sm text-gray-600">{purchaseBuyer.name}<br />{purchaseBuyer.fbnr && purchaseBuyer.fbnr !== "N/A" ? purchaseBuyer.fbnr : null} {(purchase.buyers.length > 1) ? `(${purchase.buyers.length} Käufer)` : null}</td>

                        <td className="w-32 whitespace-nowrap p-4 text-sm text-gray-600">{purchase.object_type ? purchase.object_type : "keine Angabe"}</td>

                        <td className="whitespace-nowrap p-4 text-sm text-gray-600">{purchase.gst_area ? formatArea(purchase.gst_area) : "keine Angabe"}</td>

                        <td className="whitespace-nowrap p-4 text-sm text-gray-600">{purchase.price_netto ? formatEuro(Number.parseFloat(purchase.price_netto)) : "keine Angabe"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
