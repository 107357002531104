import {
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import AppRouter from "./AppRouter";
import Nav from "./features/nav";
import NavMobile from "./features/nav-mobile";
import SelectionController from "./features/project-selection-controller";
import ProjectsFiltersModal from "./features/projects-filters-modal";
import useMediaQuery from "./hooks/use-media-query";
import LoginPage from "./pages/login";
import LoginCallbackPage from "./pages/login-callback";

/**
 *
 * @example
 */
const App = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isPrint = useMediaQuery("print");

  const isInLoginCallback = location.pathname === "/auth/login-callback";
  const isInLogin = location.pathname === "/login";

  if (isInLoginCallback) {
    return (
      <LoginCallbackPage />
    );
  }

  if (isInLogin) {
    return (
      <LoginPage />
    );
  }

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <SelectionController />

        {
          (isMobile && !isPrint)
            ? (
              <>
                <NavMobile />

                <ProjectsFiltersModal />
              </>
            )
            : (<Nav />)
        }

        <AppRouter />
      </QueryParamProvider>
    </Router>
  );
};

export default App;
