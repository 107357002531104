import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter";
import { formatArea, formatEuro } from "~/src/modules/formatters";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";

import ProjectTransaction from "./project-transaction";

/**
 *
 * @example
 */
export default function ProjectTransactions() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    transactions
  } = project;

  return (
    <SectionContainer title="Liegenschaftsankäufe">
      <div className="space-y-4">
        {
          transactions.length > 0
            ? transactions.map(({
              id,
              baurechtsdauer,
              baurechtsdauer_date,
              bauzins_gross,
              bauzins_net,
              br_stamm_ez,
              brez,
              build_area,
              buyer,
              buyer_private,
              ez_numbers,
              green_area,
              gst_numbers,
              kg: {
                bezirksgericht: {
                  code: bgCode,
                  name: bgName
                },
                code: kgCode,
                name: kgName
              } = {},
              notes,
              property_area,
              sale_price_gross,
              sale_price_net,
              seller,
              seller_private,
              shares,
              transaction_category: {
                code: transactionCategoryCode,
                name: transactionCategoryName
              } = {},
              transaction_date,
              tz_number,
              tz_year,
              usable_area
            }) => {
              const sellerPrivate = seller_private === true || false;
              const buyerPrivate = buyer_private === true || false;
              const isBaurecht = transactionCategoryCode === "baurecht";

              const transactionColumns = [
                [
                  {
                    className: "bg-gray-500/10 border-gray-500",
                    rows: [
                      {
                        label: "Vertragsdatum",
                        value: transaction_date ? dateFormatter(transaction_date) : "-"
                      },
                      {
                        label: "Objektkategorie",
                        value: transactionCategoryName ?? "-"
                      },
                      [
                        {
                          label: "GST-Fläche",
                          value: property_area ? formatArea(property_area) : "-"
                        },
                        {
                          label: "Nutzfläche",
                          value: usable_area ? formatArea(usable_area) : "-"
                        }
                      ],
                      [
                        {
                          label: "Baufläche (Gebäude)",
                          value: build_area ? formatArea(build_area) : "-"
                        },
                        {
                          label: "Baufläche (begrünt)",
                          value: green_area ? formatArea(green_area) : "-"
                        }
                      ],
                      ...(
                        isBaurecht
                          ? [
                            [
                              {
                                label: "Baurechtsdauer",
                                value: baurechtsdauer ? baurechtsdauer : "-"
                              },
                              {
                                label: "Baurecht bis",
                                value: baurechtsdauer_date ? dateFormatter(baurechtsdauer_date, "day") : "-"
                              }
                            ],
                            [
                              {
                                label: "Bauzins / Monat netto",
                                value: bauzins_net ? formatEuro(bauzins_net) : "-"
                              },
                              {
                                label: "Bauzins / Monat brutto",
                                value: bauzins_gross ? formatEuro(bauzins_gross) : "-"
                              }
                            ]
                          ]
                          : [
                            [
                              {
                                label: "Kaufpreis netto",
                                value: sale_price_net ? formatEuro(sale_price_net) : "-"
                              },
                              {
                                label: "Kaufpreis brutto",
                                value: sale_price_gross ? formatEuro(sale_price_gross) : "-"
                              }
                            ],
                            {
                              label: "Anteile",
                              value: shares ? shares : "-"
                            }
                          ]
                      ),
                      {
                        label: isBaurecht ? "Baurechtsgeber" : "Verkäufer",
                        value: sellerPrivate
                          ? "privat"
                          : (
                            <>
                              <dd>{seller?.name}</dd>

                              <dd>{seller?.reg_number}</dd>
                            </>
                          )
                      },
                      {
                        label: isBaurecht ? "Bauberechtigter" : "Käufer",
                        value: buyerPrivate
                          ? "privat"
                          : (
                            <>
                              <dd>{buyer?.name}</dd>

                              <dd>{buyer?.reg_number}</dd>
                            </>
                          )
                      }
                    ]
                  }
                ],
                [
                  {
                    className: "bg-primary/10 border-primary",
                    rows: [
                      {
                        label: "TZ",
                        value: `${tz_number ? tz_number : "-"}${tz_year ? `/${dateFormatter(tz_year, "year")}` : "-"}`
                      },
                      {
                        label: "GST-Nr.",
                        value: gst_numbers.join("; ")
                      },
                      {
                        label: "KG",
                        value: (
                          <>
                            <dd>{kgCode ?? "-"}</dd>

                            <dd>{kgName ?? ""}</dd>
                          </>
                        )
                      },
                      {
                        label: "BG",
                        value: (
                          <>
                            <dd>{bgCode ?? "-"}</dd>

                            <dd>{bgName ?? ""}</dd>
                          </>
                        )
                      },
                      ...(
                        isBaurecht
                          ? [
                            {
                              label: "BREZ",
                              value: brez ?? "-"
                            },
                            {
                              label: "EZ Stammliegenschaft",
                              value: br_stamm_ez ?? "-"
                            }
                          ]
                          : [
                            {
                              label: "EZ",
                              value: ez_numbers ? ez_numbers.join(", ") : "-"
                            }
                          ]
                      )
                    ]
                  },
                  {
                    className: "bg-primary-green/10 border-primary-green",
                    rows: [
                      {
                        label: "Bemerkungen",
                        value: notes ?? "-"
                      }
                    ]
                  }
                ]
              ];

              return (
                <ProjectTransaction columns={transactionColumns} key={id} />
              );
            })
            : <p>Liegenschaftsankäufe nicht bekannt</p>
        }
      </div>
    </SectionContainer>
  );
}
