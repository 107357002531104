import cn from "classnames";

/**
 *
 * @param props - The root object
 * @param props.className - The root object
 * @param props.children - The root object
 * @example
 */
export default function ChartLayout({ children, className }) {
  return (
    <div className={cn(className)}>
      {children}
    </div>
  );
}
