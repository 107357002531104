import {
  nullValues
} from "~/src/lib/null-values";
import {
  displayRechtsform,
  getAddressStringFromLocation,
  getCompaniesByRoleFn,
  getCompletedDate,
  getFormattedValueByKeyFn,
  getFundingType,
  getLastContractDate,
  getProjectStageByCategoryFn,
  getValueByKeyConditionallyFn
} from "~/src/lib/project-interface.js";

import { liegenschaftLabels } from "~/src/modules/labels";
// import {
//  formatEuroPerMeterSquared } from "~/src/modules/formatters";
// todo: remove this formatter from the getters,

/**
 *   this is now done with excel column format definition
 *
 * @param string
 * @example
 */
const formatEuroPerMeterSquared = (string) => string;

const project = {
  addresses: (project) => project
    ?.locations?.map?.(getAddressStringFromLocation)
    ?.filter?.((x) => x)
    ?.join?.(";"),
  // "sellers": getCompaniesByRoleFn("owner", {forAutomated: true}),
  builders: getCompaniesByRoleFn("builder"),

  name: (project) => project.name,

  lastContractDate: getLastContractDate,
  automated: (project) => project.automated,
  avgOfferPrice: getFormattedValueByKeyFn("avg_offer_price_eigennutzer", formatEuroPerMeterSquared),
  avgOfferPriceInvestor: getFormattedValueByKeyFn("avg_offer_price_investor", formatEuroPerMeterSquared),
  avgRent: getFormattedValueByKeyFn(
    "avg_miete",
    formatEuroPerMeterSquared
  ),
  avgRentBk: getFormattedValueByKeyFn(
    "avg_rent_bk",
    formatEuroPerMeterSquared
  ),
  avgSalePrice: getFormattedValueByKeyFn("avg_sale_price_eigennutzer", formatEuroPerMeterSquared),
  avgSalePriceInvestor: getFormattedValueByKeyFn("avg_sale_price_investor", formatEuroPerMeterSquared),
  completedDate: getCompletedDate,
  constructionPhase: getProjectStageByCategoryFn("construction"),
  fundingType: getFundingType,
  gka: getFormattedValueByKeyFn("gka", formatEuroPerMeterSquared),
  marketers: getCompaniesByRoleFn("marketer"),
  marketingPhase: getProjectStageByCategoryFn("marketing"),
  owners: getCompaniesByRoleFn("owner"),
  projectCertificates: (project) => ((project?.project_certificates && project?.project_certificates.length > 0)
    ? "Ja"
    : "Nein"),

  toRentOrPurchase: displayRechtsform,
  transactions: (project) => liegenschaftLabels[
    project.transaction_filter] ??
    nullValues.empty,
  unitCount: getValueByKeyConditionallyFn("count_wohnungen", (project) => !project.automated),
  unitCountAutomated: getValueByKeyConditionallyFn("count_wohnungen", (project) => project.automated)
};

const COLUMN_INTERVALS = {
  UNITS: ["B", "G"]
  // PROJECTS: ["I", "N"]
};
const YEAR_LABEL_RANGE_WORD = {
  2_019: "bis",
  2_026: "ab"
};

const statsUnits = {
  finish_year: ({ year }) => {
    const yearRangeWord = YEAR_LABEL_RANGE_WORD[year];

    return yearRangeWord
      ? `${yearRangeWord} ${year}`
      : year;
  }
};

const colGetters = {
  project,
  statsUnits
};

export { colGetters };
