import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function FinishYearSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const has2026 = items.includes("2026");
  const has2025 = items.includes("2025");
  const has2024 = items.includes("2024");
  const has2023 = items.includes("2023");
  const has2022 = items.includes("2022");
  const has2021 = items.includes("2021");
  const has2020 = items.includes("2020");
  const has2019 = items.includes("2019");

  const hasTemporary2026 = temporaryItems.includes("2026");
  const hasTemporary2025 = temporaryItems.includes("2025");
  const hasTemporary2024 = temporaryItems.includes("2024");
  const hasTemporary2023 = temporaryItems.includes("2023");
  const hasTemporary2022 = temporaryItems.includes("2022");
  const hasTemporary2021 = temporaryItems.includes("2021");
  const hasTemporary2020 = temporaryItems.includes("2020");
  const hasTemporary2019 = temporaryItems.includes("2019");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("finish_year", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Fertigstellung <span className="font-light">(Bau)</span>
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2026, hasTemporary2026)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2026")} type="checkbox" />

            <span className="ml-3">ab 2026</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2025, hasTemporary2025)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2025")} type="checkbox" />

            <span className="ml-3">2025</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2024, hasTemporary2024)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2024")} type="checkbox" />

            <span className="ml-3">2024</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2023, hasTemporary2023)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2023")} type="checkbox" />

            <span className="ml-3">2023</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2022, hasTemporary2022)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2022")} type="checkbox" />

            <span className="ml-3">2022</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2021, hasTemporary2021)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2021")} type="checkbox" />

            <span className="ml-3">2021</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2020, hasTemporary2020)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2020")} type="checkbox" />

            <span className="ml-3">2020</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has2019, hasTemporary2019)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "2019")} type="checkbox" />

            <span className="ml-3">bis 2019</span>
          </label>
        </div>
      </div>
    </div>
  );
}
