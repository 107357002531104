import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter";
import { displayRechtsform } from "~/src/modules/helpers";
import { constructionPhaseLabels, marketingPhaseLabels } from "~/src/modules/labels";

import useProjectExport from "~/src/hooks/use-project-export";

/**
 *
 * @example
 */
export default function ProjectStatus() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);
  const {
    construction_infos: {
      estimated_finish_date,
      estimated_finish_date_format
    } = {},
    timeline: {
      construction_phase_completed_date,
      construction_phase_completed_date_format,
      current_construction_phase,
      current_marketing_phase
    } = {}
  } = project;

  const completed = current_construction_phase === "construction_phase_completed";
  const completed_date = construction_phase_completed_date;

  let finishDateDisplay = null;

  if (completed && completed_date) {
    finishDateDisplay = `${dateFormatter(completed_date, construction_phase_completed_date_format)}`;
  }

  if (!completed && estimated_finish_date) {
    finishDateDisplay = `geplante Fertigstellung: ${dateFormatter(estimated_finish_date, estimated_finish_date_format)}`;
  }

  const columns = [
    ["Baustatus", [constructionPhaseLabels[current_construction_phase || "unknown"], finishDateDisplay]],
    ["Vermarktungsstatus", marketingPhaseLabels[current_marketing_phase || "unknown"]],
    ["Rechtsform", displayRechtsform(project)]
  ];

  return (
    <div className="flex w-full items-stretch justify-between gap-4">
      {
        columns.map(([label, value]) => (
          <div className="flex w-full flex-col items-center" key={label}>
            <span className="text-sm">{label}</span>

            <div className="flex flex-col items-center">
              {
                Array.isArray(value)
                  ? (
                    <>
                      <span className="text-xl font-bold">{value[0]}</span>

                      <span className="text-xs">{value[1]}</span>
                    </>
                  )
                  : <span className="text-xl font-bold">{value}</span>
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}
