import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";
import Table from "~/src/ui/tables/table";

/**
 *
 * @example
 */
export default function ProjectConstructionInfos() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    construction_infos
  } = project;

  const columnTitles = [
    "Baubeginn",
    "Geplante Fertigst.",
    "Baujahr",
    "Bautyp",
    "Sanierungsjahr"
  ];

  const {
    construction_start_date,
    construction_year,
    estimated_finish_date,
    is_new,
    reconstruction_year
  } = construction_infos;

  const rows = [
    [
      construction_start_date && dateFormatter(construction_start_date),
      estimated_finish_date && dateFormatter(estimated_finish_date),
      construction_year && dateFormatter(construction_year, "year"),
      is_new ? "Neubau" : "Sanierung",
      reconstruction_year && dateFormatter(reconstruction_year, "year")
    ]
  ];

  const columnWidths = [...Array.from({ length: 4 }).fill("2/12"), "4/12"];

  const emptyText = "Bauinformationen nicht bekannt";

  return (
    <SectionContainer title="Bauinformationen">
      <Table
        {...{
          columnTitles,
          columnWidths,
          emptyText,
          rows
        }}
      />
    </SectionContainer>
  );
}
