import { finishYearLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function FinishYearFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const finish_year = queryItems.split(",");

  /**
   *
   * @param fy
   * @example
   */
  function handleClick(fy) {
    const newFY = finish_year.filter((item) => item !== fy).join(",");

    submit("finish_year", newFY);
  }

  return finish_year.map((fy, index) => (
    <DefaultFilterTag handleClick={() => handleClick(fy)} key={`fy_tag_${index}`}>
      <span>Fertigstellung (Bau): {finishYearLabels[fy]}</span>
    </DefaultFilterTag>
  ));
}
