/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @param root0.listItemClassName - The root object
 * @param root0.children - The root object
 * @example
 */
export default function UnorderedList({
  children, className, listItemClassName
}) {
  return (
    <ul className={className}>
      {
        children?.map((child, index) => (
          <li className={listItemClassName} key={index}>{child}</li>
        ))
      }
    </ul>
  );
}
