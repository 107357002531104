import { useParams } from "react-router-dom";

import useProjectExport from "~/src/hooks/use-project-export";

import SectionContainer from "~/src/ui/containers/section-container";

/**
 *
 * @example
 */
export default function ProjectNotes() {
  const { id: projectId } = useParams();
  const { project } = useProjectExport(projectId);

  const {
    public_notes
  } = project;

  return (
    <SectionContainer title="Projektnotizen">
      <p className="text-justify">{public_notes}</p>
    </SectionContainer>
  );
}
