import {
  useEffect, useRef, useState
} from "react";

import { parseActiveFilters } from "~/src/modules/search-filter-helper";

import CertificateFilterTag from "~/src/features/filters/certificate-filter-tags";
import ConstructionPhaseFilterTags from "~/src/features/filters/construction-phase-filter-tags";
import ConstructionTypeFilter from "~/src/features/filters/construction-type-filter";
import FinishYearFilterTag from "~/src/features/filters/finish-year-filter-tags";
import FundingFilterTag from "~/src/features/filters/funding-filter-tags";
import MarketingPhaseFilterTags from "~/src/features/filters/marketing-phase-filter-tags";
import ObjektTypeFilterTag from "~/src/features/filters/objekt-typ-filter-tags";
import PolygonFilterTags from "~/src/features/filters/polygon-filter-tags";
import QuantityFilterTag from "~/src/features/filters/quantity-filter-tags";
import RechtsformFilterTag from "~/src/features/filters/rechtsform-filter";
import RegionFilterTag from "~/src/features/filters/region-filter-tags";
import TransactionFilterTag from "~/src/features/filters/transaction-filter-tags";

import useMediaQuery from "~/src/hooks/use-media-query";

import CompanyFilterTag from "~/src/ui/search-filter-tags/company-filter-tag";
import ProjectFilterTag from "~/src/ui/search-filter-tags/project-filter-tag";
import StreetFilterTag from "~/src/ui/search-filter-tags/street-filter-tag";

import HistoricFilterTags from "../../filters/historic-filter-tags";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.submit - The root object
 * @param root0.handleClearFilters - The root object
 * @param root0.onClick - The root object
 * @param root0.selectionFilter - The root object
 * @param root0.children - The root object
 * @param root0.filterModals - The root object
 * @example
 */
export default function ProjectFilterTags({
  filterModals,
  query,
  submit
}) {
  const isPrint = useMediaQuery("print");

  const divReference = useRef(null);

  const [childrenCount, setChildrenCount] = useState(0);

  /**
   *
   * @param key
   * @example
   */
  function handleClick(key) {
    submit(key, null);
  }

  // const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  // const activeSelection = (currentSelectionStatus !== "all");
  // const setSelectionResetByFilter = useStore((state) => state.setSelectionResetByFilter);

  const activeFilters = parseActiveFilters(query);

  // const resetSelection = () => {
  //   if (selectionFilter) {
  //     setSelectionResetByFilter(true);
  //   }
  // };

  useEffect(() => {
    setChildrenCount(divReference.current.children.length);
  }, [divReference.current]);

  return (
    <div className="flex w-full break-inside-avoid flex-wrap gap-x-2 gap-y-1 py-0.5 pr-6 print:max-h-[50mm] print:overflow-hidden print:p-0" ref={divReference}>
      {/* <SelectionFilterTag handleClick={() => resetSelection()} label={selectionFilter} /> */}
      <RegionFilterTag onClick={() => filterModals.setRegionFilterModal(true)} queryItems={query?.region} submit={submit} />

      <CertificateFilterTag onClick={() => filterModals.setEsgCertificatesFilterModal(true)} queryItems={query?.certificates} submit={submit} />

      <StreetFilterTag handleClick={() => handleClick("street")} label={query?.streetLabel} locationType={query?.locationType} radius={query?.radius} slug={query?.street} />

      <ProjectFilterTag handleClick={() => handleClick("name")} label={query?.name} />

      <CompanyFilterTag handleClick={() => handleClick("company")} label={query?.companyLabel} slug={query?.company} />

      <ObjektTypeFilterTag queryItems={query?.objekttyp} submit={submit} />

      <FinishYearFilterTag queryItems={query?.finish_year} submit={submit} />

      <ConstructionPhaseFilterTags queryItems={query?.construction_phase} submit={submit} />

      <MarketingPhaseFilterTags queryItems={query?.marketing_phase} submit={submit} />

      <RechtsformFilterTag queryItems={query?.rechtsform} submit={submit} />

      <QuantityFilterTag queryItems={query?.quantity} submit={submit} />

      <TransactionFilterTag queryItems={query?.liegenschaft} submit={submit} />

      <FundingFilterTag queryItems={query?.foerderung} submit={submit} />

      <ConstructionTypeFilter queryItems={query?.construction_type} submit={submit} />

      <PolygonFilterTags queryItems={query?.polygon} submit={submit} />

      {isPrint && <HistoricFilterTags queryItems={query?.automated} submit={submit} />}
    </div>
  );
}
