import { priceLabels } from "~/src/modules/labels";

import ChartLegend from "~/src/ui/charts/legend";
import LineChart from "~/src/ui/charts/line-chart";

/* eslint-disable import/prefer-default-export */
/* eslint-disable func-style */
import { generateLineData } from "./tools";

/**
 *
 * @param root0 - The root object
 * @param root0.graphLabels - The root object
 * @param root0.statistic - The root object
 * @example
 */
function MarketTrendOfferChartForPrint({ graphLabels, statistic }) {
  return graphLabels
    .map((graphLabel) => {
      const lineData = generateLineData(graphLabel, statistic);

      return (
        <div className="w-full break-inside-avoid space-y-4 bg-white" key={graphLabel}>
          <h2 className="text-md font-medium text-gray-900">{priceLabels[graphLabel]} Trend</h2>

          <div className="h-[19rem]">
            <LineChart
              data={lineData.chartData}
              x={{ type: "month" }}
            />
          </div>

          <ChartLegend simple data={lineData.legendData} />
        </div>
      );
    });
}

export { MarketTrendOfferChartForPrint };
