/* eslint-disable no-shadow */
/* eslint-disable import/max-dependencies */

/* eslint-disable max-lines-per-function */

import { Temporal } from "@js-temporal/polyfill";
import {
  useEffect,
  useMemo,
  useState
} from "react";

import DetailStatsTools from "~/src/lib/detail-stats-tools";
import { addElementUniq, nullFn } from "~/src/lib/standard";

import { priceLabels } from "~/src/modules/labels";

import useMediaQuery from "~/src/hooks/use-media-query";

import ChartLegend from "~/src/ui/charts/legend";
import MultiLineChart from "~/src/ui/charts/multi-line-chart";
import PriceSelect from "~/src/ui/selects/price-select";

import { areaGroups } from "~/src/schema/detail-stats";

import EmptyChartContainer from "../empty-chart-container";

import { generateLineData } from "./tools";

const {
  PlainYearMonth
} = Temporal;

/**
 *
 * @param props - The root object
 * @param props.statistic - The root object
 * @example
 */
export default function MarketTrendByAreaSaleChart({ statistic }) {
  const isPrint = useMediaQuery("print");

  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);

  const labelsOfNonemptyGraphs = DetailStatsTools
    .getVariablesWithData(statistic);

  const activeGraphLabel = selectedGraphLabel ?? labelsOfNonemptyGraphs?.[0];

  const lineData = useMemo(
    () => (activeGraphLabel
      ? generateLineData(activeGraphLabel, statistic)
      : null),
    [statistic, activeGraphLabel]
  );

  const defaultAreaGroups = ["61 - 80"];

  const [areaGroupsWithoutUnselected, setAreaGroupsWithoutUnselected] =
    useState(defaultAreaGroups);

  const [hoveredAreaGroup, setHoveredAreaGroup] = useState(null);

  const allGraphLabels = Object.keys(statistic);

  useEffect(() => {
    if (lineData) {
      const availableAreaGroups = lineData.legendData
        .map(({ id }) => id) ?? [];

      const initialAreaGroups = availableAreaGroups.includes("61 - 80")
        ? defaultAreaGroups
        : [availableAreaGroups[0]];

      setAreaGroupsWithoutUnselected(initialAreaGroups);
    }
  }, [lineData]);

  if (isPrint) {
    return labelsOfNonemptyGraphs
      .map((graphLabel) => {
        const lineData = generateLineData(graphLabel, statistic);

        return (
          <div className="w-full break-inside-avoid space-y-4 bg-white" key={graphLabel}>
            <h2 className="text-md font-medium text-gray-900">{priceLabels[graphLabel]} Trend nach Fläche</h2>

            <div className="h-[19rem]">
              <MultiLineChart
                activeAreaGroups={areaGroups}
                data={lineData.chartData}
                x={{ type: "month" }}
              />
            </div>

            <ChartLegend
              simple
              activeAreaGroups={areaGroups}
              data={lineData.legendData}
              displayAreaGroups={areaGroups}
              setActiveAreaGroups={nullFn}
              setHoveredAreaGroup={nullFn}
            />
          </div>
        );
      });
  }

  if (!labelsOfNonemptyGraphs?.length || !lineData) {
    return (
      <EmptyChartContainer
        allGraphLabels={allGraphLabels}
        chartLabel={`${priceLabels.salePricePerSquareMeter} Trend nach Fläche`}
      />
    );
  }

  const activeAreaGroups = addElementUniq(
    areaGroupsWithoutUnselected,
    hoveredAreaGroup
  );

  return (
    <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white p-2 lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="text-md font-medium text-gray-900">{priceLabels.salePricePerSquareMeter} Trend nach Fläche</h2>

          {

            /*
          <MetaTooltip
            {...((barData) && {
              meta: barData.meta
            })}
          />
            */
          }
        </div>

        <PriceSelect
          buttonClassName="w-80"
          className="w-80 lg:self-end"
          enabledOptions={labelsOfNonemptyGraphs}
          onChange={setSelectedGraphLabel}
          options={allGraphLabels}
          value={activeGraphLabel}
        />
      </div>

      <div className="relative h-[450px] w-full">
        <div className="absolute h-[450px] w-full">
          {(lineData.chartData.some(({ data }) => data.length > 0))
            ? (
              <MultiLineChart
                activeAreaGroups={activeAreaGroups}
                data={lineData.chartData}
                x={{ type: "month" }}
              />
            )
            : null}

        </div>
      </div>

      <div className="w-full">
        <ChartLegend
          simple
          activeAreaGroups={areaGroupsWithoutUnselected}
          data={lineData.legendData}
          displayAreaGroups={activeAreaGroups}
          setActiveAreaGroups={setAreaGroupsWithoutUnselected}
          setHoveredAreaGroup={setHoveredAreaGroup}
        />
      </div>
    </div>
  );
}
