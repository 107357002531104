import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @param root0.className - The root object
 * @param root0.sub - The root object
 * @example
 */
export default function Header({
  children, className, sub = false
}) {
  return (
    <span
      className={cn("uppercase break-after-avoid", {
        "text-base": sub,
        "text-lg": !sub
      }, className)}
    >
      {children}
    </span>
  );
}
