import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

import { errorHasHttpStatus } from "../lib/error";
import httpStatus from "../lib/http-status";

import useMediaQuery from "./use-media-query";

/**
 *
 * @param root0 - The root object
 * @param root0.version - The root object
 * @example
 */
export default function useAuth({ version = 0 } = { version: 0 }) {
  const isPrint = useMediaQuery("print");
  const {
    data,
    error,
    mutate
  } = useSWR(`/auth/session?v=${version}`, fetcher);

  const loggedOut = errorHasHttpStatus({
    error,
    status: httpStatus.forbidden
  });

  return {
    isError: error,
    isLoading: !error && !data,
    loggedOut,
    mutate,
    user: data?.payload
  };
}
