import useAuth from "~/src/hooks/use-auth";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Route, useHistory
} from "react-router-dom";

/**
 *
 * @param props - The root object
 * @param props.children - The root object
 * @example
 */
const ProtectedRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const [authVersion, setAuthVersion] = useState(1);

  const {
    isError, isLoading, loggedOut, user
  } = useAuth({ version: authVersion });

  const [notUnauthorizedErrorCount, setNotUnauthorizedErrorCount] = useState(0);

  const setAuthVersionDelayed = async () => {
    const delayInMilliseconds = (3 ** notUnauthorizedErrorCount) * 100;

    await new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));

    setAuthVersion(authVersion + 1);
  };

  useEffect(() => {
    if (isError && !loggedOut && notUnauthorizedErrorCount < 5) {
      setNotUnauthorizedErrorCount(notUnauthorizedErrorCount + 1);

      setAuthVersionDelayed();
    }
  }, [
    isError,
    // loggedOut,
    authVersion
  ]);

  useEffect(() => {
    if (loggedOut || notUnauthorizedErrorCount >= 5) {
      // history.replace("/login");
      console.log("STILL 403 AFTER 5 SESSION ATTEMPTS");
    }
    if (!isError && !loggedOut && notUnauthorizedErrorCount > 0) {
      setNotUnauthorizedErrorCount(0);
      setAuthVersion(1);
    }
  }, [
    isError,
    // loggedOut,
    notUnauthorizedErrorCount
  ]);

  if (isLoading || (isError && !loggedOut)) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        }

        const newUrl = new URL(window.location.href);

        newUrl.pathname = "/login";

        newUrl.search = "";

        newUrl.hash = "";

        window.location.replace(newUrl);
      }}
    />
  );
};

export default ProtectedRoute;
