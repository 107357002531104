import ProjectLink from "~/src/ui/links/project-link/index.jsx";

const ProjectTitle = ({
  checked,
  disabled,
  handleSelect,
  project
}) => (
  <div className="flex items-start gap-2 pl-1">

    <input checked={checked} className="mt-1.5 size-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50" disabled={disabled} onChange={handleSelect} type="checkbox" />

    <ProjectLink inNewTab backlink="projects" project={project}>
      <p className="inline-block text-base font-medium text-primary sm:text-lg">
        <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
      </p>
    </ProjectLink>

  </div>
);

export default ProjectTitle;
