import { LatLngBounds } from "leaflet";
import drawLocales from "leaflet-draw-locales";
import {
  useEffect,
  useRef,
  useState
} from "react";
import {
  MapContainer,
  Marker,
  WMSTileLayer,
  ZoomControl
} from "react-leaflet";
import useInterval from "use-interval";

import useStore from "~/src/hooks/use-store";

drawLocales("de-at");

/**
 *
 * @example
 */
function useForceUpdate() {


  /**
   * integer state
   */
  const [value, setValue] = useState(0);

  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

/**
 *
 * @param props - The root object
 * @param props.purchase - The root object
 * @param props.slideIsOpen - The root object
 * @example
 */
export default function PurchaseMap({ purchase, slideIsOpen }) {
  const mapReference = useRef(null);
  const forceUpdate = useForceUpdate();

  const [searchLocation, setSearchLocation] = useState(null);
  const [polygonState, setPolygonState] = useState(null);
  const [controlMounted, setControlMounted] = useState(false);
  const [controlState, setControlState] = useState(null);
  const [shouldFocusMap, setShouldFocusMap] = useState(null);
  const [intervalCount, setIntervalCount] = useState(0);
  const [intervalDelay, setIntervalDelay] = useState(100);
  const [pageLocked, setPageLocked] = useState(true);

  const mapViewState = useStore((state) => state.mapViewState);
  const setMapViewState = useStore((state) => state.setMapViewState);
  const mapViewStateFresh = useStore((state) => state.mapViewStateFresh);
  const setMapViewStateFresh = useStore((state) => state.setMapViewStateFresh);
  const readPolygonsFromQuery = useStore((state) => state.readPolygonsFromQuery);
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  useEffect(() => {
    if (mapReference.current) {
      mapReference.current.invalidateSize();
      mapReference.current.attributionControl.setPrefix("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapReference.current, purchase]);

  useEffect(() => {
    const map = mapReference.current;

    if (!map) {
      return;
    }

    if (purchase) {
      map.fireEvent("dataload");
    }
    else {
      map.fireEvent("dataloading");
    }
  }, [purchase]);

  useEffect(() => {
    if (slideIsOpen) {
      forceUpdate();
    }
  }, [slideIsOpen]);

  /**
   *
   * @param control
   * @example
   */
  function handleMounted(control) {
    if (!controlMounted) {
      setControlMounted(true);
      setControlState(control);
    }
  }

  /**
   *
   * @example
   */
  function resetInterval() {
    setShouldFocusMap(false);
    setIntervalCount(0);
    setIntervalDelay(100);
  }

  /**
   *
   * @example
   */
  function focusMap() {
    const map = mapReference.current;
    const group = groupRef.current;

    try {
      const bounds = new LatLngBounds([[purchase.coords_y, purchase.coords_x], [purchase.coords_y, purchase.coords_x]]);

      map.fitBounds(bounds.pad(0.3));

      return true;
    }
    catch {
      return false;
    }
  }

  useInterval(() => {
    if (intervalCount > 10 || !shouldFocusMap) {
      resetInterval();
    }
    else {
      const mapFocused = focusMap();

      if (mapFocused) {
        resetInterval();
      }
      else {
        setIntervalCount(intervalCount + 1);
        setIntervalDelay(Math.floor(intervalDelay * 1.5));
      }
    }
  }, shouldFocusMap ? intervalDelay : null, false);

  return (
    <MapContainer
      bounds={[purchase.coords_y, purchase.coords_x]}
      center={[purchase.coords_y, purchase.coords_x]}
      dragging={true}
      maxZoom={18}
      minZoom={6}
      ref={mapReference}
      scrollWheelZoom={true}
      tap={false}
      zoom={15}
      zoomControl={false}
      zoomDelta={1}
      zoomSnap={0}
      style={{
        backgroundColor: "#fff",
        borderRadius: "5px"
      }}
    >
      <WMSTileLayer
        attribution={"&copy; <a href=\" http://basemap.at\">Basemap.at</a>"}
        bounds={new LatLngBounds([46.431_817_328_5, 9.479_969_516_65], [49.039_074_205_1, 16.979_666_782_3])}
        format="image/png"
        key="project-map"
        maxNativeZoom={18}
        maxZoom={34}
        minNativeZoom={6}
        noWrap={true}
        updateInterval={50}
        url="https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png"
        version="1.0.0"
        subdomains={[
          "maps",
          "maps1",
          "maps2",
          "maps3",
          "maps4"
        ]}
      />

      <ZoomControl position="topright" />

      <Marker
        position={[purchase.coords_y, purchase.coords_x]}
      />
    </MapContainer>
  );
}
