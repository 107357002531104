/* eslint-disable camelcase */
import {
  BooleanParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  createEnumParam,
  withDefault
} from "use-query-params";

/**
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const ApiModel = {

  /**
   *
   */
  get locationTypesWithLocationId() {
    return [
      "plz",
      "city",
      "city_plz",
      "street"
    ];
  },

  /**
   *
   */
  get locationTypesWithRadius() {
    return ["street_address"];
  },

  /**
   *
   */
  get locationTypes() {
    return [
      "plz",
      "city",
      "city_plz",
      "street",
      "street_address"
    ];
  },

  /**
   *
   */
  get schemaForUseQueryParams() {
    return ({
      activeBBox: withDefault(BooleanParam, false),
      automated: BooleanParam,
      bBox: StringParam,
      centerLat: NumberParam,
      centerLng: NumberParam,
      certificates: withDefault(DelimitedArrayParam, []),
      company: StringParam,
      companyLabel: StringParam,
      construction_phase: StringParam,
      construction_type: StringParam,
      direction: StringParam,
      finish_year: StringParam,
      foerderung: StringParam,
      include_automated: BooleanParam,
      liegenschaft: StringParam,
      locationId: StringParam,
      locationType: createEnumParam(ApiModel.locationTypes),
      mapZoom: NumberParam,
      marked: BooleanParam,
      marketing_phase: StringParam,
      name: StringParam,
      objekttyp: StringParam,
      page: NumberParam,
      polygon: withDefault(DelimitedArrayParam, []),
      printZoom: NumberParam,
      quantity: StringParam,
      radius: NumberParam,
      rechtsform: StringParam,
      region: withDefault(DelimitedNumericArrayParam, []),
      selection: BooleanParam,
      slugs: StringParam,
      sort: StringParam,
      stats: StringParam,
      street: StringParam,
      streetLabel: StringParam,
      userId: NumberParam
    });
  }

};
