import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.columns - The root object
 * @example
 */
export default function ProjectTransaction({ columns }) {
  return (
    <div className="flex break-inside-avoid gap-2">
      {
        columns.map((column, columnIndex) => (
          <div className="w-full space-y-2" key={columnIndex}>
            {
              column.map(({ className, rows }, blockIndex) => (
                <div className={cn("space-y-2 border-t-4 p-4", { "h-full": columnIndex === 0 && blockIndex === 0 }, className)} key={`${columnIndex}-${blockIndex}`}>
                  {
                    rows.map((row, rowIndex) => (Array.isArray(row)
                      ? (
                        <div className="grid grid-cols-2 gap-4">
                          {
                            row.map(({ label, value }, cellIndex) => (
                              <dl key={`${columnIndex}-${blockIndex}-${rowIndex}-${cellIndex}`}>
                                <dt className="uppercase">{label}</dt>

                                {
                                  typeof value === "string"
                                    ? <dd>{value}</dd>
                                    : value
                                }
                              </dl>
                            ))
                          }
                        </div>
                      )
                      : (
                        <dl key={`${columnIndex}-${blockIndex}-${rowIndex}`}>
                          <dt className="uppercase">{row.label}</dt>

                          {
                            typeof row.value === "string"
                              ? <dd>{row.value}</dd>
                              : row.value
                          }
                        </dl>
                      )))
                  }
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
}
